<template>
  <v-dialog
      :value="dialog"
      :persistent="true"
      max-width="700px"
  >
    <v-card>
      <v-card-title class="justify-space-between">
        <v-skeleton-loader
            type="table-cell"
            :loading="loading"
            style="background-color: white"
        >
          <span v-if="this.data && this.data.id">{{ $t('system.edit_gift') }}</span>
          <span v-else>{{ $t('system.create_gift') }}</span>
        </v-skeleton-loader>
        <v-btn
            class="mt-1 ml-3"
            color="secondary"
            text
            fab
            small
            @click.stop="close"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider class="mt-2 mb-6" />
      <v-skeleton-loader
          :class="{' mt-4 ml-4 mr-4': loading}"
          type="article, actions"
          :loading="loading"
          style="background-color: white"
      >
        <v-card-text
            class="card-text"
        >
          <v-form
              @keyup.native.enter="valid && save()"
              v-model="valid"
              ref="form"
          >
            <v-text-field
                v-model="formData.name"
                :rules="[rules.required]"
                dense
                flat
                :label="$t('system.name')"
                outlined
            />
            <v-text-field
                v-model="formData.stock_quantity"
                :rules="[rules.required]"
                dense
                flat
                type="number"
                :label="$t('system.quantity')"
                outlined
            />
            <v-checkbox
                v-model="formData.is_active"
                :label="$t('system.is_active')"
            />
            <v-spacer />
          </v-form>
        </v-card-text>
        <v-divider
            class="mt-0 mb-0"
        />
        <v-card-actions
            class="pb-3 pt-3 pr-3 d-flex justify-content-end"
        >
          <v-btn
              text
              color="secondary"
              @click="close"
          >
            {{ $t('system.cancel') }}
          </v-btn>
          <v-btn
              color="primary"
              @click="save"
          >
            {{ $t('system.save') }}
          </v-btn>
        </v-card-actions>
      </v-skeleton-loader>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'EditCreateGift',

  data() {
    return {
      valid: true,
      menu: false,
      formData: {
        name: '',
        stock_quantity: null,
        is_active: true,
      },
      price: {
        price: null,
        date_from: null,
      },
      rules: {
        required: value => !!value || this.$t('field_must_be_valid'),
      },
    };
  },

  props: {
    data: {
      type: Object,
      default: () => {
        return {
          name: null,
          stock_quantity: null,
          is_active: true,
        };
      },
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    data: {
      handler() {
        if (this.data) {
          this.formData = JSON.parse(JSON.stringify(this.data));
        }
      },
      deep: true,
    },
  },

  methods: {
    close() {
      this.$emit('close');
      this.$refs.form.resetValidation();
    },

    save() {
      this.$refs.form.validate();
      if (this.valid) {
        this.$emit('save', this.formData);
        this.$refs.form.resetValidation();
      }
    },
  },
};
</script>
