<template>
  <v-dialog
      :value="dialog"
      @click:outside="close"
      :persistent="true"
      max-width="800px"
  >
    <v-card>
      <v-card-title class="justify-space-between">
        {{ $t('system.gift_details') }}
        <v-btn
            class="mt-1 ml-3"
            color="secondary"
            text
            fab
            small
            @click.stop="close"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider class="mt-1 mb-2" />
      <v-skeleton-loader
          :class="{' mt-4 ml-4 mr-4': loading}"
          type="article, actions"
          :loading="loading"
          style="background-color: white"
      >
        <v-card-text
            class="card-text pb-2 pl-6 pr-6"
        >
          <v-spacer />
          <v-row>
            <v-col
                cols="12"
                xs="12"
                sm="6"
            >
              <div>{{ $t(`system.name`) }}</div>
              <p
                  class="text--primary d-flex"
              >
                {{ data.name ? data.name : $t('system.no_data') }}
              </p>
              <v-spacer />
              <div>{{ $t(`system.stock_quantity`) }}</div>
              <p
                  class="text--primary d-flex"
              >
                {{ data.stock_quantity }}
              </p>
            </v-col>
            <v-col
                cols="12"
                xs="12"
                sm="6"
            >
              <div>{{ $t(`system.is_active`) }}</div>
              <p
                  class="text--primary d-flex"
              >
                <v-chip
                    small
                    :color="data.is_active ? 'success' : 'error'"
                >
                  {{ data.is_active ? $t('system.yes') : $t('system.no') }}
                </v-chip>
              </p>
              <v-spacer />
              <div>{{ $t(`system.created_at`) }}</div>
              <p
                  class="text--primary d-flex"
              >
                {{ data.created_at ? data.created_at : $t('system.no_data') }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider
            class="mt-1 mb-4"
        />
        <v-card-text
            class="card-text pb-2 pl-6 pr-6"
        >
          <v-row>
            <v-col
                cols="12"
                xs="12"
                sm="6"
            >
              <h5>{{ $t('system.prices') }}</h5>
              {{ $t('system.current_price') }}: <strong>{{ data.price || $t('system.no_price') }}</strong>
            </v-col>
            <v-col
                cols="12"
                xs="12"
                sm="6"
            >
              <v-form
                  @keyup.native.enter="addPrice()"
                  ref="form"
                  v-model="valid"
                  class="d-flex mt-4"
                  style="flex: 1 2"
              >
                <v-text-field
                    :label="$t('system.price')"
                    placeholder="10.00"
                    v-model="formData.price"
                    type="number"
                    dense
                    class="mr-3"
                    style="width: 33%"
                    :rules="required"
                />
                <v-menu
                    v-model="createMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="formData.date_from"
                        :label="$t('system.date_from')"
                        readonly
                        dense
                        :rules="required"
                        v-bind="attrs"
                        v-on="on"
                    />
                  </template>
                  <v-date-picker
                      v-model="formData.date_from"
                      @input="createMenu = false"
                  />
                </v-menu>
                <v-btn
                    small
                    color="primary"
                    class="ml-3"
                    @click="addPrice()"
                    :disabled="localLoading"
                    :loading="localLoading"
                >
                  {{ $t('system.add') }}
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
          <v-data-table
              :headers="headers"
              :items="data.prices"
              :loading="localLoading"
              :items-per-page="5"
              class="elevation-0 mt-4"
          >
            <template v-slot:item.price="{ item }">
              <input
                  @keyup.enter="editPrice(item)"
                  @keyup.esc="refresh()"
                  style="width: 100px"
                  :readonly="!item.edit"
                  class="pl-1 pr-1"
                  type="number"
                  :class="{'edit-input': item.edit}"
                  v-model="item.price"
              />
            </template>
            <template v-slot:item.date_from="{ item }">
              <input
                  v-if="!item.edit"
                  style="width: 100px"
                  readonly
                  class="pl-1 pr-1"
                  v-model="item.date_from"
              />
              <v-menu
                  v-else
                  v-model="item.menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <input
                      @keyup.enter="editPrice(item)"
                      @keyup.esc="refresh()"
                      style="width: 100px"
                      readonly
                      class="pl-1 pr-1"
                      :class="{'edit-input': item.edit}"
                      v-model="item.date_from"
                      v-bind="attrs"
                      v-on="on"
                  />
                </template>
                <v-date-picker
                    v-model="item.date_from"
                    @input="item.menu = false"
                />
              </v-menu>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu
                  offset-y
                  v-if="!item.edit"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                      color="red"
                      dark
                      icon
                      small
                      text
                  >
                    <v-icon size="15">
                      fas fa-times
                    </v-icon>
                  </v-btn>
                </template>
                <v-card class="px-3 py-2">
                  {{ $t('system.are_you_sure') }}?
                  <v-btn
                      class="ml-2"
                      color="red red-darken-2"
                      dark
                      small
                      @click.stop="deletePrice(item.id)"
                  >{{ $t('system.yes') }}
                  </v-btn>
                </v-card>
              </v-menu>
              <v-btn
                  v-if="!item.edit"
                  @click.stop="item.edit = true"
                  class="ml-2"
                  color="primary"
                  dark
                  icon
                  small
                  text
              >
                <v-icon size="15">
                  fas fa-edit
                </v-icon>
              </v-btn>
              <v-btn
                  v-if="item.edit"
                  @click.stop="editPrice(item)"
                  class="ml-2"
                  color="primary"
                  icon
                  text
                  small
                  :disabled="item.loading"
                  :loading="item.loading"
              >
                <v-icon size="15">
                  fas fa-check
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider
            class="mt-1 mb-0"
        />
        <v-card-actions
            class="pb-3 pt-3 pr-3 d-flex justify-content-end"
        >
          <v-btn
              text
              color="red"
              class="mr-2"
              @click="toggleDelete"
          >
            {{ $t('system.delete') }}
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="toggleEdit"
          >
            {{ $t('system.edit') }}
          </v-btn>
        </v-card-actions>
      </v-skeleton-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'GiftDetails',

  data() {
    return {
      dialogStatus: false,
      createMenu: false,
      localLoading: false,
      valid: true,
      formData: this.constructFormData(),
      headers: [
        {
          text: this.$t('system.price'),
          sortable: true,
          value: 'price',
        },
        {
          text: this.$t('system.date_from'),
          sortable: true,
          value: 'date_from',
        },
        {
          text: this.$t('system.actions'),
          sortable: false,
          value: 'actions',
          align: 'end',

        },
      ],
      required: [
        v => !!v || this.$t('system.required'),
      ],
    };
  },

  props: {
    data: {
      type: Object,
      default: () => {
        return {
          name: null,
          stock_quantity: null,
          is_active: null,
          created_at: null,
        };
      },
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async addPrice() {
      this.$refs.form.validate();
      if (this.valid) {
        this.localLoading = true;
        try {
          await axios.post('sk_visits/price', this.prepareData());
          this.refresh();
          this.formData = this.constructFormData();
        } catch (e) {
          console.log(e);
        } finally {
          setTimeout(() => this.localLoading = false, 500);
        }
        this.$refs.form.resetValidation();
      }
    },

    async editPrice(item) {
      if (!item.price || !item.date_from) {
        return;
      }
      item.loading = true;
      try {
        await axios.put(`sk_visits/price/${item.id}`, {
          gift_id: this.data.id,
          price: item.price,
          date_from: item.date_from.toString(),
        });
        setTimeout(() => {
          item.edit = false;
        }, 500);
        this.refresh();
      } catch (e) {
        console.log(e);
      } finally {
        setTimeout(() => {
          item.loading = false;
        }, 500);
      }
    },

    async deletePrice(id) {
      this.localLoading = true;
      try {
        await axios.delete(`sk_visits/price/${id}`);
        this.refresh();
      } catch (e) {
        console.log(e);
      } finally {
        setTimeout(() => this.localLoading = false, 500);
      }
    },

    refresh() {
      this.$emit('refresh', this.data.id);
    },

    prepareData() {
      return {
        ...this.formData,
        gift_id: this.data.id,
      };
    },

    constructFormData() {
      return {
        price: null,
        date_from: null,
      };
    },

    toggleEdit() {
      this.$emit('edit', this.data);
    },

    toggleDelete() {
      this.$emit('delete', this.data);
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>

<style
    scoped
    lang="scss"
>

.text--primary {
  font-size: 17px;

  &.title {
    font-size: 30px;
  }
}

.edit-input {
  border-bottom: 2px dotted #888 !important;
  outline: none;
}

</style>
