<template>
  <v-dialog
      :value="dialog"
      @click:outside="close"
      max-width="800px"
  >
    <v-card>
      <v-card-title class="justify-space-between">
        {{ $t('system.gift_delete') }}: {{ data.name }}
        <v-btn
            class="mt-1 ml-3"
            color="secondary"
            text
            fab
            small
            @click.stop="close"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider class="mt-1 mb-2" />
      <v-skeleton-loader
          :class="{' mt-4 ml-4 mr-4': loading}"
          type="article, actions"
          :loading="loading"
          style="background-color: white"
      >
        <v-card-text
            class="card-text pb-2 pl-6 pr-6"
        >
          <v-spacer />
          <p class="text--primary title mb-2">
            {{ $t('system.if_you_sure') }}
          </p>
          <v-spacer />
        </v-card-text>
        <v-divider
            class="mt-1 mb-0"
        />
        <v-card-actions
            class="pb-3 pt-3 pr-3 d-flex justify-content-end"
        >
          <v-btn
              text
              color="secondary"
              @click="close"
          >
            {{ $t('system.cancel') }}
          </v-btn>
          <v-btn
              color="red"
              dark
              @click="deleteGift"
          >
            {{ $t('system.delete') }}
          </v-btn>
        </v-card-actions>
      </v-skeleton-loader>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'DeleteGift',

  props: {
    data: {
      type: Object,
      default: () => {
        return {
          name: '',
        };
      },
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },

    deleteGift() {
      this.$emit('deleteGift', this.data);
    },
  },
};
</script>

<style
    scoped
    lang="scss"
>

.text--primary {
  font-size: 17px;

  &.title {
    font-size: 30px;
  }
}

</style>
