<template>
  <div class="view-container">
    <div
        class="view"
    >
      <div class="view-body">
        <v-skeleton-loader
            class="mx-auto"
            type="table"
            :loading="loading && !formLoaded"
        >
          <v-data-table
              :footer-props="dataTableOptions.footerProps"
              :options.sync="dataTableOptions"
              :server-items-length="dataTableOptions.totalItems"
              :headers="headers"
              :items="gifts"
              :loading="loading"
              class="elevation-0 clickable-row"
              @click:row="viewGift"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-btn
                    normal
                    class="buttonCustom"
                    dark
                    elevation="0"
                    @click="createGift"
                >
                  <v-icon
                      size="17"
                      class="mr-2"
                  >fas fa-plus
                  </v-icon>
                  {{ $t('system.add_gift') }}
                </v-btn>
                <v-divider
                    class="mx-4"
                    inset
                    vertical
                />
                <v-spacer />
                <SimpleGroupBy
                    :group-by="groupBy"
                    :group-by-elements="groupByElements"
                    :title="$t('system.show_just')"
                    :default-group-by="null"
                    @setGroupBy="isActiveFilter"
                />
                <v-divider
                    class="mx-4"
                    inset
                    vertical
                />
                <v-text-field
                    v-model="searchData.name"
                    class="mt-6"
                    dense
                    flat
                    :label="$t('system.search')"
                    outlined
                    style="max-width: 260px"
                    @input="search(false)"
                    v-on:keyup.enter="search(true)"
                />
              </v-toolbar>
            </template>
            <template v-slot:item.is_active="{ item }">
              <v-chip
                  small
                  :color="item.is_active ? 'success' : 'error'"
              >
                {{ item.is_active ? $t('system.yes') : $t('system.no') }}
              </v-chip>
            </template>
            <template v-slot:item.price="{ item }">
              {{ item.price || $t('system.no_price') }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                  @click.stop="toggleDeleteGift(item)"
                  class="ml-2"
                  color="red"
                  dark
                  icon
                  small
              >
                <v-icon size="15">
                  fas fa-times
                </v-icon>
              </v-btn>
              <v-btn
                  @click.stop="editGift(item)"
                  class="ml-2"
                  color="primary"
                  dark
                  icon
                  small
              >
                <v-icon size="15">
                  fas fa-edit
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:no-data>
              {{ $t('system.no_data') }}
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
      <DeleteGift
          :data="editedData"
          :dialog="deleteDialog"
          :loading="dialogLoading"
          @close="close"
          @deleteGift="deleteGift"
      />
      <GiftDetails
          :data="editedData"
          :dialog="viewDialog"
          :loading="dialogLoading"
          @close="close"
          @edit="editGift"
          @delete="toggleDeleteGift"
          @refresh="refreshViewGift"
      />
      <EditCreateGift
          :data="editedData"
          :dialog="editDialog"
          :loading="dialogLoading"
          @close="close"
          @save="saveGift"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import GiftDetails from '@/components/Gifts/GiftDetails';
import DeleteGift from '@/components/Gifts/DeleteGift';
import EditCreateGift from '@/components/Gifts/EditCreateGift';
import SimpleGroupBy from '@/components/Filters/SimpleGroupBy';

export default {
  name: 'GiftsList',

  components: {
    GiftDetails,
    DeleteGift,
    EditCreateGift,
    SimpleGroupBy,
  },

  data() {
    return {
      loading: true,
      formLoaded: false,
      createDialog: false,
      viewDialog: false,
      editDialog: false,
      deleteDialog: false,
      dialogLoading: false,
      editedData: {
        id: null,
        name: '',
        stock_quantity: 0,
        is_active: true,
      },
      viewData: {},
      gifts: [],
      searchData: {
        name: '',
        is_active: null,
      },
      searchingInterval: null,
      dataTableOptions: {
        page: 1,
        itemsPerPage: localStorage.getItem(`${this.$route.name.trim()}_ItemsPerPage`) ? parseInt(JSON.parse((localStorage.getItem(`${this.$route.name.trim()}_ItemsPerPage`))), 10) : 10,
        sortBy: ['name'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        totalItems: 0,
        footerProps: {
          itemsPerPageOptions: [10, 25, 50, 100],
          showFirstLastPage: true,
        },
      },
      groupBy: null,
      groupByElements: ['is_active', 'is_inactive'],
      headers: [
        {
          text: this.$t('system.name'),
          sortable: true,
          value: 'name',
        },
        {
          text: this.$t('system.stock_quantity'),
          sortable: true,
          value: 'stock_quantity',
        },
        {
          text: this.$t('system.is_active'),
          sortable: true,
          value: 'is_active',
        },
        {
          text: this.$t('system.current_price'),
          sortable: true,
          value: 'price',
        },
        {
          text: this.$t('system.actions'),
          sortable: false,
          value: 'actions',
          align: 'end',
        },
      ],
    };
  },

  watch: {
    dataTableOptions: {
      handler() {
        if (this.formLoaded) {
          this.fetchData();
          localStorage.setItem(`${this.$route.name.trim()}_ItemsPerPage`, JSON.stringify(this.dataTableOptions.itemsPerPage));
        }
      },
      deep: true,
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.loading = true;
      const searchData = {
        name: this.searchData.name,
        direction: 'desc',
        per_page: this.dataTableOptions.itemsPerPage,
        page: this.dataTableOptions.page,
        order_by: 'id',
      };
      if (this.searchData.is_active !== null) {
        searchData.is_active = !!this.searchData.is_active;
      }
      try {
        const {data} = await axios.post(`/sk_visits/gift/search`, searchData);
        this.gifts = data.data;
        this.dataTableOptions.totalItems = data.meta.total;
        this.formLoaded = true;
        this.loading = false;
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
      }
    },

    search(instant) {
      if (instant) {
        this.fetchData();
        return;
      }
      clearTimeout(this.searchingInterval);
      this.searchingInterval = setTimeout(() => {
        this.fetchData();
      }, 1500);
    },

    isActiveFilter(is_active) {
      this.loading = true;
      this.groupBy = is_active;
      if (is_active === 'is_active') {
        this.searchData.is_active = true;
      } else if (is_active === 'is_inactive') {
        this.searchData.is_active = false;
      } else {
        this.searchData.is_active = null;
      }
      this.fetchData();
    },

    async viewGift(element) {
      this.viewDialog = true;
      this.dialogLoading = true;
      try {
        const {data} = await axios.get(`/sk_visits/gift/${element.id}`);
        this.editedData = data;
        this.editedData.prices = data.prices.map((element) => {
          return {
            ...element,
            date_from: element.date_from.substr(0, 10),
            edit: false,
            loading: false,
          };
        });
        this.dialogLoading = false;
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
        this.dialogLoading = false;
      }
    },

    refreshViewGift(id) {
      this.viewGift({id: id});
    },

    async saveGift(data) {
      this.dialogLoading = true;
      const obj = {
        name: data.name,
        stock_quantity: data.stock_quantity,
        is_active: data.is_active,
      };
      if (data.id) {
        try {
          const response = await axios.put(`/sk_visits/gift/${data.id}`, obj);
          await this.fetchData();
          await this.viewGift(response.data);
          this.dialogLoading = false;
          this.editDialog = false;
        } catch (error) {
          console.log('error', error);
          this.dialogLoading = false;
        }
      } else {
        try {
          const response = await axios.post(`/sk_visits/gift`, obj);
          await this.fetchData();
          await this.viewGift(response.data);
          this.dialogLoading = false;
          this.editDialog = false;
        } catch (error) {
          console.log('error', error);
          this.dialogLoading = false;
        }
      }
    },

    async deleteGift(data) {
      this.dialogLoading = true;
      try {
        await axios.delete(`/sk_visits/gift/${data.id}`);
        await this.fetchData();
        this.dialogLoading = false;
        this.close();
      } catch (error) {
        console.log('error', error);
        this.dialogLoading = false;
      }
    },

    createGift() {
      this.editDialog = true;
      this.editedData = {
        name: '',
        stock_quantity: null,
        is_active: true,
      };
    },

    toggleDeleteGift(element) {
      this.viewDialog = false;
      this.deleteDialog = true;
      this.editedData = element;
    },

    editGift(element) {
      this.viewDialog = false;
      this.editDialog = true;
      this.editedData = element;
    },

    close() {
      this.viewDialog = false;
      this.deleteDialog = false;
      this.editDialog = false;
      this.createDialog = false;
    },
  },
};
</script>
